
<template>
  <div class="head-bar-tip">
    <a id="barHref" href="https://my.astrowarp.net/" target="_blank"
      >Upgrade to Plus - $0.9 Only for the First Month！</a
    >
  </div>
</template>
<style lang="scss" scoped>
body {
  padding-top: 105px !important;
}

.head-bar-tip {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  text-align: center;
  height: 40px;
  line-height: 40px;
  background: #2562ea;
  // transparent linear-gradient(90deg, #B786FF 0%, #6609FC 100%) 0% 0% no-repeat padding-box;
  color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    a {
      font-size: 12px;
    }
  }
}

.global-header {
  margin-top: 40px;
}

#barHref {
  padding: 0 6px;
  color: #fff;
  font-weight: 600;
}

#barHref:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .head-bar-tip {
    line-height: 40px;
    height: 40px;
    display: block;
    font-size: 14px;
    padding: 0 ;
  }
}
</style>

